<template>
  <div>
    <b-alert :show="showAlert" variant="danger">
      <span>{{ alertMsg }}</span>
    </b-alert>
    <div class="row">
      <div class="col-md-12">
        <div class="card card-custom gutter-b">
          <div class="card-body">
            <div class="row">
              <div class="col-md-12"></div>
            </div>
            <div class="row">
              <div class="col-md-9 pl-0 pr-2">
                <DualListBox
                  :source="companies"
                  :destination="selectedCompanies"
                  label="name"
                  @onChangeList="onChangeList"
                />
                <div class="pt-4 px-4">
                  <button
                    class="btn btn-primary btn-block font-weight-bold"
                    @click="generate"
                  >
                    Generate
                  </button>
                </div>
              </div>
              <div class="col-md-3 px-0">
                <DatePicker
                  v-model="range"
                  :masks="masks"
                  locale="hr"
                  is-range
                  is24hr
                  is-expanded
                  :columns="$screens({ default: 1, lg: 1 })"
                />
                <button
                  @click="last7Days"
                  class="btn btn-light-primary btn-block font-weight-bold mt-2"
                >
                  Posljednjih 7 dana
                </button>
                <button
                  class="btn btn-light-primary btn-block font-weight-bold"
                >
                  Posljednjih 30 dana
                </button>
                <button
                  class="btn btn-light-primary btn-block font-weight-bold"
                >
                  Ovaj mjesec
                </button>
                <button
                  class="btn btn-light-primary btn-block font-weight-bold"
                >
                  Prošli mjesec
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="generatedResults.length > 0" class="row">
      <div class="col-md-12">
        <div class="card card-custom gutter-b">
          <div class="card-header d-flex align-items-center">
            <span class="card-label font-weight-bolder text-dark">
              <span>{{ range.start | formatDate }}</span>
              <span class="flex-shrink-0 my-2 mx-3"
                ><i class="la la-arrow-right"></i
              ></span>
              <span>{{ range.end | formatDate }}</span>
            </span>
            <div class="card-toolbar">
              <a
                href=""
                class="btn btn-light btn-hover-primary font-weight-bold"
              >
                Export
              </a>
            </div>
          </div>
          <div class="card-body py-4">
            <b-table
              striped
              responsive
              sticky-header="500px"
              :items="generatedResults"
              :fields="fields"
              foot-clone
            >
            </b-table>
          </div>
          <div class="card-footer py-6">
            <div class="d-flex justify-content-between">
              <span class="font-weight-bolder text-dark">Osnovica: </span>
              <span>{{ base }} <span> €</span></span>
            </div>
            <div class="d-flex justify-content-between">
              <span class="font-weight-bolder text-dark">PDV: (25%)</span>
              <span>{{ tax }} <span> €</span></span>
            </div>
            <div class="d-flex justify-content-between mt-2">
              <span class="font-size-lg font-weight-bolder text-dark"
                >Ukupno:
              </span>
              <span class="font-size-lg font-weight-bolder text-dark"
                >{{ total }} <span> €</span></span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-spinner
      v-if="showSpinner"
      variant="primary"
      label="Spinning"
    ></b-spinner>
    <div v-if="isGenerated" class="row">
      <div class="col-md-12">
        <WeekDaysTraffic v-if="isGenerated" :traffic="generatedResults" />
      </div>
    </div>
    <div v-if="isGenerated" class="row">
      <div class="col-md-12">
        <HoursTraffic v-if="isGenerated" :traffic="generatedResults" />
      </div>
    </div>
    <div v-if="isGenerated" class="row">
      <div class="col-md-12">
        <WeekTraffic v-if="isGenerated" :traffic="generatedResults" />
      </div>
    </div>
    <div v-if="isGenerated" class="row">
      <div class="col-md-12">
        <MonthTraffic v-if="isGenerated" :traffic="generatedResults" />
      </div>
    </div>
    <div v-if="isGenerated" class="row">
      <div class="col-md-12">
        <DaysTraffic
          v-if="isGenerated"
          ::type="'company'"
          :traffic="generatedResults"
          :range="range"
        />
      </div>
    </div>
    <div v-if="isGenerated" class="row">
      <div class="col-md-12">
        <DevicesTraffic v-if="isGenerated" :traffic="generatedResults" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ApiService from '@/core/services/api.service'
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import format from 'date-fns/format'
import add from 'date-fns/add'
import DualListBox from '@/view/content/components/dual-list-box/DualListBox'
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import { dateFilter } from 'vue-date-fns'
import DevicesTraffic from '@/view/pages/finance-report/_components/DevicesTraffic'
import DaysTraffic from '@/view/pages/finance-report/_components/DaysTraffic'
import MonthTraffic from '@/view/pages/finance-report/_components/MonthTraffic'
import WeekTraffic from '@/view/pages/finance-report/_components/WeekTraffic'
import HoursTraffic from '@/view/pages/finance-report/_components/HoursTraffic'
import WeekDaysTraffic from '@/view/pages/finance-report/_components/WeekDaysTraffic'

export default {
  name: 'CompanyReport',
  filters: {
    date: dateFilter,
  },
  components: {
    DatePicker,
    DualListBox,
    DevicesTraffic,
    DaysTraffic,
    MonthTraffic,
    WeekTraffic,
    HoursTraffic,
    WeekDaysTraffic,
  },
  data() {
    return {
      companies: [],
      selectedCompanies: [],
      generatedResults: [],
      fields: [
        {
          key: 'imei',
          label: 'IMEI',
          sortable: false,
        },
        {
          key: 'amount',
          label: 'Iznos',
          sortable: true,
        },
        {
          key: 'createdAt',
          label: 'Datum i vrijeme',
          sortable: true,
          class: 'text-right',
        },
      ],
      resultsDays: [],
      totalItems: null,
      range: {
        start: null,
        end: null,
      },
      masks: {
        input: 'dd.MM.yyyy - HH:mm:ss',
      },

      showAlert: false,
      alertMsg: null,

      base: 0,
      tax: 0,
      total: 0,

      isGenerated: false,
      showSpinner: false,
    }
  },
  computed: {
    ...mapGetters(['currentUserRole']),
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Izvještaji po kompaniji', route: '/finance-report/company' },
    ])

    ApiService.get('companies').then(({ data }) => {
      this.totalItems = data['hydra:member'].length
      this.companies = [...data['hydra:member']]
    })
  },
  methods: {
    generate() {
      if (this.selectedCompanies.length === 0) {
        this.alertMsg = 'Molimo oznacite kompaniju/e'
        this.showAlert = true

        return
      }

      this.showSpinner = true

      const after = format(this.range.start, "yyyy-MM-dd'T'HH:mm:ss")
      const before = format(
        add(new Date(this.range.end), { days: 1 }),
        "yyyy-MM-dd'T'HH:mm:ss",
      )

      this.selectedCompanies.forEach((company) => {
        company.locations.forEach((location, key) => {
          ApiService.get(
            'transactions',
            `?location=${location}&createdAt[after]=${after}&createdAt[before]=${before}&order[createdAt]=desc&pagination=false`,
          ).then(({ data }) => {
            data['hydra:member'].forEach(
              ({ imei, device, amount, createdAt, paymentType }) => {
                this.base += parseFloat(amount) * 0.8
                this.tax += parseFloat(amount) - parseFloat(amount) * 0.8
                this.total += parseFloat(amount)

                if (paymentType !== 'pay_token') {
                  this.generatedResults.push({
                    imei: imei,
                    device: device,
                    amount: amount,
                    createdAt: format(
                      new Date(createdAt),
                      'dd.MM.yyyy @ HH:mm:ss',
                    ),
                  })
                }
              },
            )

            if (key + 1 == company.locations.length) {
              setTimeout(() => {
                this.base = this.currencyFormat(this.base)
                this.tax = this.currencyFormat(this.tax)
                this.total = this.currencyFormat(this.total)

                this.isGenerated = true
                this.showSpinner = false
              }, 2000)
            }
          })
        })
      })
    },
    last7Days() {
      setTimeout(() => {
        this.range.start = new Date(2021, 1, 6)
        this.range.end = new Date(2021, 1, 23)
      }, 500)
    },
    onChangeList: function ({ source, destination }) {
      this.locations = source
      this.selectedCompanies = destination
    },
    currencyFormat(num) {
      return num
        .toFixed(2)
        .replace('.', ',')
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
    },
  },
}
</script>
